.m-data {
	&01,
	&02 {
		display: table;
		
		> dl {
			display: table-row;
			
			dt,
			dd {
				display: table-cell;
			}
			dd {
				padding-left: 2em;
			}
		}
		
		strong {
			color: #e50012;
			font-size: percentage( 55 / 30 );
		}
		em {
			color: #e50012;
			font-style: normal;
			font-weight: bold;
		}
	}
	&01 {
		&:not(:first-child) {
			margin-top: 30px;
		}
		
	}
}