.m-banners {
	&-col02 {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		
		&-item {
			width: percentage( 470 / $pc-center );
			margin-top: 40px;
			margin-left: percentage( ($pc-center - 470 * 2) / $pc-center );
			
			&:nth-child(-n+2) {
				margin-top: 0;
			}
			&:nth-child(2n+1) {
				margin-left: 0;
			}
			@media #{$sp} {
				width: 100%;
				margin-top: 20px;
				margin-left: 0;
				
				&:nth-child(-n+2) {
					margin-top: 20px;
				}
				&:first-child {
					margin-top: 0;
				}
			}
			
			a {
				position: relative;
				display: block;
				
				&:hover {
					opacity: 1 !important;
				}
			}
			&__thumb {
				position: relative;
				display: block;
				width: 100%;
				background-color: #fff;
				overflow: hidden;
				
				> img {
					display: block;
					width: 100%;
					// transition: transform .8s $ease-out-cubic, opacity .2s;
				}
			}
			/*
			a:hover &__thumb {
				> img {
					transform: scale(1.05);
					opacity: 0.75;
				}
			}
			*/
			&__ttl {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding-bottom: 16px;
				text-align: center;
				color: #ffffff;
				font-size: 2.0rem;
				font-weight: $fw-bold;
				line-height: 1;
				transition: bottom .4s $ease-out-cubic;
			}
			a:hover &__ttl {
				bottom: 1rem;
			}
		}
	}
	&-col03 {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		
		&-item {
			width: percentage( 300 / $pc-center );
			margin-top: 30px;
			margin-left: percentage( ($pc-center - 300 * 3) / 2 / $pc-center );
			
			&:nth-child(-n+3) {
				margin-top: 0;
			}
			&:nth-child(3n+1) {
				margin-left: 0;
			}
			@media #{$sp} {
				width: 49%;
				margin-top: 2%;
				margin-left: 2%;
				
				&:nth-child(-n+3) {
					margin-top: 2%;
				}
				&:nth-child(3n+1) {
					margin-left: 2%;
				}
				&:nth-child(-n+2) {
					margin-top: 0;
				}
				&:nth-child(2n+1) {
					margin-left: 0;
				}
			}
			
			a {
				position: relative;
				display: block;
				
				&:hover {
					opacity: 1 !important;
				}
			}
			&__thumb {
				position: relative;
				display: block;
				width: 100%;
				background-color: #fff;
				overflow: hidden;
				
				> img {
					display: block;
					width: 100%;
					// transition: transform .8s $ease-out-cubic, opacity .2s;
				}
			}
			/*
			a:hover &__thumb {
				> img {
					transform: scale(1.05);
					opacity: 0.75;
				}
			}
			*/
			&__ttl {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding-bottom: 16px;
				text-align: center;
				color: #ffffff;
				font-size: 2.0rem;
				font-weight: $fw-bold;
				line-height: 1;
				text-shadow: 0 1px 10px #005d95;
				transition: bottom .4s $ease-out-cubic;
				
				@media #{$sp} {
					font-size: 1.6rem;
				}
				> small {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 140px;
					height: 28px;
					margin: 8px auto 0;
					border: 1px solid #ffffff;
					border-radius: 14px;
					color: #ffffff;
					font-size: 1.2rem;
					font-weight: $fw-normal;
					line-height: 1;
					
					@media #{$sp} {
						width: 120px;
						height: 24px;
						border-radius: 12px;
						font-size: 1.1rem;
					}
				}
			}
			a:hover &__ttl {
				bottom: 1rem;
			}
		}
	}
	&-center {
		justify-content: center;
	}
}