.m-tab {
	&__list {
		ul {
			display: flex;
			width: 100%;
			
			li {
				display: block;
				flex: 1 1 auto;
				padding-left: 1px;
				
				&:first-child {
					padding-left: 0;
				}
				a {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0.75em 1em;
					background-color: #dde5ed;
					color: #1a4e8a;
					font-size: 1.8rem;
					font-weight: $fw-bold;
					line-height: 1.5;
				}
			}
		}
	}
	&__container {
		margin-top: 60px;
	}
}