.m-network {
	&-detail {
		&__container {
			display: flex;
			justify-content: space-between;
			
			@media #{$sp} {
				flex-direction: column;
			}
		}
		&__pictures {
			width: 410px;
			
			@media #{$sp} {
				width: 100%;
				max-width: 410px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		&__info {
			width: calc(100% - 480px);
			
			@media #{$sp} {
				width: 100%;
				margin-top: 32px;
			}
		}
		&__description {
			margin-top: 64px;
			
			> p {
				font-size: 2.0rem;
				line-height: 1.5;
			}
		}
	}
}