@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Noto+Sans+JP:wght@400;700&display=swap');

$fc-default: $color-black;

$ff-default: Arial, 'Noto Sans JP', sans-serif;
$ff-catch: Arial, 'メイリオ', 'Meiryo','Noto Sans JP', sans-serif;
$ff-en: Arial, sans-serif;

$fw-light: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-bold: 700;
