@charset "utf-8";

/*===============================================
 header
===============================================*/
.l-header {
	position: fixed;
	left: 0;
	top: 0;
	z-index: $z-header;
	width: 100%;
	height: 150px;
	background-color: $color-white;
	transform: translateY(0%);
	transition: transform 1.2s $ease-out-circ;
	
	@media #{$sp} {
		height: spvw( 90 );
	}
	
	&.is-hidden {
		transform: translateY(-100%);
	}
	&__in {
		padding-top: 30px;
		@include clearfix;
		
		@media #{$sp} {
			padding-top: spvw(20);
		}
	}
	&__logo {
		float: left;
		
		@media #{$sp} {
			width: spvw( 120 );
		}
		> a {
			display: block;
			
			> img {
				display: block;
			}
		}
	}
	&__corpo {
		float: right;
		width: 432px;
		height: 36px;
		margin-top: 10px;
		background: url(#{$img}common/header-corpo.svg) no-repeat right top;
		background-size: contain;
		
		@media #{$sp} {
			width: spvw( 432 * 0.4 );
			height: spvw( 36 * 0.4 );
			margin-top: 0;
		}
	}
	&__admin {
		clear: right;
		float: right;
		margin-top: 8px;
		color: #0062a0;
		font-size: 22px;
		font-weight: bold;
	}
	&__nav {
		clear: right;
		float: right;
		display: flex;
		margin-top: 8px;
		
		@media #{$tb} {
			clear: none;
			width: calc( 100% - 216px );
			justify-content: flex-end;
			margin-top: 28px;
		}
		@media #{$sp} {
			width: calc( 100% - #{spvw(120)} );
			margin-top: spvw( 10 );
		}
		
		> ul {
			display: flex;
			
			@media #{$tb} {
				pointer-events: none;
				opacity: 0;
				flex-direction: column;
				position: absolute;
				left: 0;
				top: 150px;
				width: 100%;
				max-height: calc( 100vh - 150px );
				background-color: #fff;
				box-shadow: 0 6px 6px rgba(0,0,0,0.5);
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
			}
			@media #{$sp} {
				top: spvw( 90 );
				max-height: calc( 100vh - #{spvw( 90 )} );
			}
			> li {
				padding-right: 10px;
				
				@media #{$tb} {
					padding-right: 0;
				}
				> a {
					position: relative;
					display: block;
					padding: 20px 12px 28px 18px;
					color: #000000;
					font-size: 17px;
					font-weight: $fw-medium;
					line-height: 1;
					white-space: nowrap;
					
					&:before {
						@media #{$pc} {
							position: absolute;
							left: 50%;
							top: 48px;
							width: 36px;
							height: 3px;
							background-color: #0082ba;
							opacity: 0;
							transform: translateX(-50%) scaleX(0);
							transition: opacity .2s, transform .2s ease-out;
							content: '';
						}
					}
					&.is-current:before {
						@media #{$pc} {
							opacity: 1;
							transform: translateX(-50%) scaleX(1);
						}
					}
				}
				&:hover > a {
					&:before {
						@media #{$pc} {
							opacity: 1;
							transform: translateX(-50%) scaleX(1);
						}
					}
				}
			}
		}
	}
	&__nav.is-active {
		> ul {
			@media #{$tb} {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
	&__spmenu {
		display: none;
		
		@media #{$tb} {
			position: relative;
			display: block;
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
		.border {
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			height: 3px;
			background-color: #1a4e8a;
			transition: opacity .2s, transform .2s ease-out;
			
			&:nth-child(1) {
				transform: translateY(-2px);
			}
			&:nth-child(2) {
				transform: translateY(-10px);
			}
			&:nth-child(3) {
				transform: translateY(6px);
			}
		}
	}
	&__nav.is-active &__spmenu {
		.border {
			&:nth-child(1) {
				opacity: 0;
			}
			&:nth-child(2) {
				transform: translateY(-2px) rotate(45deg);
			}
			&:nth-child(3) {
				transform: translateY(-2px) rotate(-45deg);
			}
		}
	}
	&__parent {
		> a {
			border-radius: 8px 8px 0 0;
			
			@media #{$tb} {
				border-radius: 0;
			}
			&:after {
				display: inline-block;
				width: 10px;
				height: 7px;
				margin: 0 0 0 6px;
				background: url(#{$img}common/gnav-arw.png) no-repeat;
				content: '';
			}
		}
		&:hover > a {
			@media #{$pc} {
				background-color: #dde5ed;
				color: #1a4e8a;
				opacity: 1 !important;
			}
		}
		&.is-hover > a {
			background-color: #dde5ed;
			color: #1a4e8a;
			opacity: 1 !important;
			
			&:after {
				transform: rotate(180deg);
			}
		}
	}
	&__child {
		display: none;
		position: absolute;
		left: 0;
		z-index: 99;
		top: 100%;
		width: 100%;
		
		@media #{$tb} {
			position: relative;
			top: 0;
		}
	}
	/*
	&__parent:hover &__child {
		@media #{$pc} {
			display: block;
		}
	}
	*/
	&__parent.is-hover &__child {
		display: block;
	}
	&__search {
		padding: 18px 0 0 0;
		
		@media #{$tb} {
			padding: 0;
		}
		> a {
			display: block;
			width: 24px;
			height: 24px;
			padding: 0;
			background: url(#{$img}common/gnav-search.png) no-repeat center center;
		}
	}
	&__language {
		padding: 16px 0 0 0;
		
		@media #{$tb} {
			padding: 0;
		}
		
		&__select {
			position: relative;
			
			select {
				display: block;
				width: 50px;
				border: 0;
				outline: 0;
				padding: 5px 12px 5px 5px;
				appearance: none;
				text-align: center;
				color: #0189b6;
				font-size: 1.4rem;
			}
			&:after {
				content: "";
				margin-top: -2px;
				border-width: 4px 4px 0;
				border-style: solid;
				border-color: #0189b6 transparent;
				position: absolute;
				top: 50%;
				right: 4px;
				pointer-events: none;
				z-index: 2;
			}
		}
	}
	&-searchbox {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: #dde5ed;
		display: none; // default
		
		.gsc-input-box {
			padding-top: 0;
			padding-bottom: 0;
		}
		
		&__in {
			max-width: 440px;
			margin-left: auto;
			
			@media #{$sp} {
				max-width: none;
				margin-left: 0;
			}
		}
	}
	&-drop {
		width: 100%;
		background-color: #dde5ed;
		
		&__in {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 40px 0;
			
			@media #{$tb} {
				padding: 0 0 spvw(40);
			}
			@media #{$sp} {
				padding: 0 0 40px;
			}
		}
		&__heading {
			// width: 160px;
			width: percentage( 160 / $pc-center );
			flex: 0 0 none;
			
			@media #{$sp} {
				display: none;
			}
		}
		&__ttl {
			&-ja {
				display: block;
				font-size: 24px;
				line-height: 1;
			}
			&-en {
				display: block;
				margin-top: 16px;
				color: #1a4e8a;
				font-size: 16px;
				line-height: 1;
			}
		}
		&__body {
			display: flex;
			justify-content: space-between;
			flex: 1 1 auto;
			padding: 0 0 0 40px;
			border-left: 1px solid #ffffff;
			
			@media #{$tb} {
				flex-direction: column;
				padding: 0;
				border-left: 0;
			}
		}
		&__column {
			@media #{$sp} {
				margin-top: 1.6rem;
				
				&:first-child {
					margin-top: 0;
				}
			}
			a {
				color: #1a4e8a;
			}
			> ul {
				font-size: 15px;
				
				@media #{$tb} {
					display: flex;
					flex-wrap: wrap;
					
					> li {
						margin-right: 2em;
					}
				}
			}
			> dl {
				margin-top: 15px;
				
				&:first-child {
					margin-top: 0;
				}
				dt {
					margin-top: 10px;
					color: #1a4e8a;
					font-size: 15px;
					font-weight: $fw-medium;
					line-height: 1.5;
					
					&:first-child {
						margin-top: 0;
					}
				}
				dd {
					font-size: 12px;
					line-height: 1.5;
					
					ul {
						margin-top: 10px;
						
						@media #{$tb} {
							display: flex;
							flex-wrap: wrap;
						}
						li {
							margin-top: 4px;
							padding-left: 10px;
							background: url(#{$img}common/fnav-arw.png) no-repeat left 5px;
							
							&:first-child {
								margin-top: 0;
							}
							
							@media #{$sp} {
								margin-right: 2em;
								
								&:first-child {
									margin-top: 4px;
								}
							}
						}
					}
				}
			}
		}
		&-group {
			position: relative;
			flex: 1 1 auto;
			margin: 0 10px;
			
			&__ttl {
				color: #1a4e8a;
				font-size: 15px;
				font-weight: $fw-medium;
			}
			&__in {
				display: flex;
				justify-content: space-between;
				margin-top: 5px;
				padding-top: 10px;
				border-top: 1px solid #ffffff;
				
				@media #{$sp} {
					flex-direction: column;
				}
			}
		}
		/*
		&--company &__body > &__column:nth-child(1) {
			// width: 220px;
			width: percentage( 220 / ($pc-center - 200) );
			
			@media #{$sp} {
				width: 100%;
			}
		}
		&--company &__body > &-group {
			// width: 560px;
			width: percentage( 560 / ($pc-center - 200) );
			
			@media #{$sp} {
				width: 100%;
			}
		}
		&--company &__body > &-group:nth-child(2) &__column:nth-child(2) {
			min-width: 56%;
			
			@media #{$sp} {
				min-width: 100%;
				width: 100%;
			}
		}
		
		&--products &__body > &__column {
			min-width: 20%;
			
			@media #{$sp} {
				min-width: 100%;
				width: 100%;
			}
		}
		
		&--recruit &__body > &-group:nth-child(1) {
			// width: 525px;
			width: percentage( 525 / ($pc-center - 200) );
			
			@media #{$sp} {
				width: 100%;
			}
		}
		&--recruit &__body > &-group:nth-child(1) &__column:nth-child(2) {
			// width: 300px;
			// width: percentage( 300 / ($pc-center - 200) );
			min-width: 56%;
			
			@media #{$sp} {
				min-width: 100%;
				width: 100%;
			}
		}
		&--recruit &__body > &-group:nth-child(2) {
			// width: 240px;
			width: percentage( 240 / ($pc-center - 200) );
			
			@media #{$sp} {
				width: 100%;
			}
		}
		*/
		
		&-entry {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 100%;
			
			@media #{$sp} {
				position: relative;
			}
			> a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 48px;
				background-color: #1a4e8a;
				color: #ffffff;
				font-size: 15px;
				font-weight: $fw-bold;
				line-height: 1;
				
				> span {
					position: relative;
					top: -2px;
				}
			}
		}
		
		&--download &__body > &-group:nth-child(1) {
			width: 160px;
			
			@media #{$sp} {
				width: 100%;
			}
		}
		&--download &__body > &-group:nth-child(2) {
			width: 360px;
			
			@media #{$sp} {
				width: 100%;
			}
		}
		&--download &__body > &-group:nth-child(2) &__column:nth-child(2) {
			min-width: 50%;
			
			@media #{$sp} {
				min-width: 100%;
				width: 100%;
			}
		}
		&--download &__body > &-group:nth-child(3) {
			width: 220px;
			
			@media #{$sp} {
				width: 100%;
			}
		}
	}
}
