.m-message {
	position: relative;
	
	&__picture {
		@media #{$tb} {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}
		@media #{$sp} {
			position: relative;
			height: auto;
		}
		> img {
			display: block;
			width: 100%;
			
			@media #{$tb} {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateX(-40%) translateY(-50%);
				width: auto;
				max-width: none;
				height: 100%;
			}
			@media #{$sp} {
				position: relative;
				left: 0;
				top: 0;
				transform: none;
				width: 100%;
				height: auto;
			}
		}
	}
	&__content {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		
		@media #{$tb} {
			position: relative;
			height: auto;
			padding-top: 3.2rem;
			padding-bottom: 3.2rem;
		}
		@media #{$sp} {
			padding-top: 1.6rem;
			padding-bottom: 1.6rem;
		}
		.m-ttl01 {
			@media #{$tb} {
				margin-bottom: 3.0rem;
			}
		}
	}
	&__heading {
		&-sp {
			display: none;
			
			@media #{$sp} {
				display: block;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 60%;
				box-sizing: border-box;
				
				.m-ttl01 {
					margin-bottom: #{spvw(10)};
					font-size: #{spvw(16)};
				}
				.m-ttl02 {
					margin-bottom: 0;
					font-size: #{spvw(13)};
					font-weight: $fw-normal;
				}
			}
		}
		&-left {
			@media #{$sp} {
				left: 5px;
			}
		}
		&-right {
			@media #{$sp} {
				left: 35%;
			}
		}
		&-pc {
			@media #{$sp} {
				display: none;
			}
		}
	}
	&__in {
		width: 90%;
		max-width: 1280px;
		margin: 0 auto;
		
		@media #{$tb} {
			width: 92%;
		}
	}
	&__in > &__left,
	&__in > &__right {
		width: 65%;
		
		@media #{$sp} {
			width: 100%;
		}
	}
	&__in > &__right {
		margin-left: auto;
	}
}