.m-bg {
	padding-top: 30px;
	padding-bottom: 30px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	
	&__in {
		max-width: 1100px;
		margin-left: auto;
		margin-right: auto;
	}
}