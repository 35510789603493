.m-download {
	padding-top: 100px;
	padding-bottom: 60px;
	
	@media #{$tb2} {
		padding-top: 120px;
	}
	@media #{$sp} {
		padding-top: spvw( 150+20 );
	}
	&-search {
		position: fixed;
		left: 0;
		top: 150px;
		z-index: $z-search;
		width: 100%;
		height: 60px;
		background-color: #dde5ed;
		transition: top 1.2s $ease-out-circ;
		
		/*
		@media #{$tb} {
			top: 210px;
		}
		*/
		@media #{$tb2} {
			height: 120px;
		}
		@media #{$sp} {
			top: spvw( 90 );
			height: spvw( 150 );
		}
		
		.l-header.is-hidden + .l-main & {
			top: 0px;
		}
		
		&__container {
			display: flex;
			align-items: center;
			height: 60px;
			
			@media #{$tb2} {
				height: 100px;
				flex-wrap: wrap;
				justify-content: flex-start;
				padding-top: 10px;
			}
			@media #{$sp} {
				flex-wrap: wrap;
				height: spvw( 140 );
				padding-top: spvw(4);
				padding-bottom: spvw(4);
			}
		}
		&__column {
		}
		&__keyword {
			@media #{$sp} {
				width: 100%;
			}
			> input {
				width: 220px;
				padding: 13px 6px;
				border: 1px solid #ccc;
				
				/*
				@media #{$tb} {
					width: 130px;
				}
				*/
				@media #{$sp} {
					width: 100%;
					padding: spvw( 10 ) spvw( 6 );
					box-sizing: border-box;
				}
			}
		}
		&__category {
			padding-left: 10px;
			
			@media #{$sp} {
				width: 23.125%;
				padding-left: 2.5%;
				
				&:nth-child(2) {
					padding-left: 0;
				}
			}
			@media only screen and (max-width: 610px) {
				&:nth-child(4) {
					width: 48.75%;
				}
				&:nth-child(5) {
					padding-left: 0;
				}
			}
		}
		&__space {
			@media #{$tb2} {
				width: 100%;
			}
			@media #{$tb} {
				width: 0;
			}
			@media #{$sp} {
				width: 100%;
			}
			@media only screen and (max-width: 610px) {
				width: 0;
			}
		}
		&__submit {
			width: 120px;
			margin-left: auto;
			
			@media #{$sp} {
				width: spvw(98);
			}
		}
		&__clear {
			width: 72px;
			margin-left: 5px;
			
			@media #{$sp} {
				width: spvw(64);
			}
		}
		&__label {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 41px;
			padding: 0 30px 0 20px;
			background-color: #ffffff;
			border: 1px solid #ccc;
			cursor: pointer;
			
			@media #{$sp} {
				height: auto;
				/*
				height: spvw(41);
				*/
				padding: spvw( 10 ) spvw(24) spvw( 10 ) spvw(10);
			}
			&:after {
				position: absolute;
				right: 16px;
				top: 50%;
				width: 4px;
				height: 4px;
				border-right: 1px solid #999;
				border-bottom: 1px solid #999;
				transform: rotate(45deg) translateY(-50%);
				content: '';
				
				@media #{$sp} {
					right: spvw(12);
				}
			}
		}
		&__select {
			position: fixed;
			left: 0;
			top: 210px;
			width: 100%;
			overflow: hidden;
			pointer-events: none;
			transition: top 1.2s $ease-out-circ;
			
			@media #{$tb2} {
				top: 270px;
			}
			@media #{$sp} {
				top: spvw(240);
			}
			.l-header.is-hidden + .l-main & {
				top: 60px;
				
				@media #{$tb} {
					top: 60px;
				}
				@media #{$sp} {
					top: spvw(98);
				}
			}
			&.is-visible {
				&:before {
					position: absolute;
					top: 0;
					width: 0;
					height: 0;
					border-bottom: 6px solid #fff;
					border-left: 3px solid transition;
					border-right: 3px solid transition;
					content: '';
				}
			}
			&__bg {
				position: relative;
				background-color: #dde5ed;
				transform: translateY(-100%);
				transition: transform .4s $ease-out-cubic;
				pointer-events: auto;
			}
			&.is-visible &__bg {
				transform: translateY(0%);
			}
			&__in {
				padding-top: 10px;
				padding-bottom: 20px;
			}
			&__box {
				padding: 10px 20px;
				background-color: #f8f8f8;
				
				label {
					display: inline-flex;
					align-items: center;
					margin-right: 1em;
					white-space: nowrap;
					
					> span {
						margin-left: 4px;
					}
				}
				table {
					th,
					td {
						vertical-align: top;
					}
					th {
						padding-right: 1em;
						white-space: nowrap;
						text-align: left;
						
						&:nth-child(2) {
							text-align: right;
						}
					}
				}
			}
		}
	}
	&-result {
		&__content {
			margin-right: 110px;
			
			@media #{$sp} {
				margin-right: 0;
			}
			
			a {
				color: #1a4e8a;
			}
		}
		&__check {
			margin-top: 40px;
			margin-bottom: -20px;
			margin-right: 110px;
			background-color: #dde5ed;
			
			@media #{$sp} {
				margin-right: 0;
			}
			label {
				display: block;
				padding: 10px;
				
				input,
				span {
					vertical-align: middle;
				}
				input {
					margin-right: 0.5em;
				}
			}
		}
		&__list {
			display: flex;
			flex-wrap: wrap;
			margin-top: 40px;
			padding-right: 110px;
			
			@media #{$sp} {
				padding-right: 0;
			}
		}
		&-item {
			position: relative;
			width: 49%;
			margin-left: 2%;
			margin-top: 2%;
			
			&:nth-child(-n+2) {
				margin-top: 0;
			}
			&:nth-child(2n+1) {
				margin-left: 0;
			}
			@media #{$tb} {
				width: 100%;
				margin-left: 0;
				
				&:nth-child(-n+2) {
					margin-top: 2%;
				}
				&:first-child {
					margin-top: 0;
				}
			}
			@media #{$sp} {
				width: 100%;
				margin-left: 0;
				
				&:nth-child(-n+2) {
					margin-top: 2%;
				}
				&:first-child {
					margin-top: 0;
				}
			}
			
			&__in {
				display: flex;
				flex-wrap: wrap;
				padding: 5px;
				border: 1px solid #ccc;
				background-color: #fff;
				
				> input {
					position: absolute;
					left: 0;
					top: 0;
				}
			}
			&__thumb {
				width: 100px;
				margin-right: 20px;
				
				a {
					position: relative;
					display: block;
					
					&:before {
						position: absolute;
						right: 1px;
						bottom: 1px;
						width: 0;
						height: 0;
						border-right: 14px solid #f8f8f8;
						border-bottom: 14px solid #f8f8f8;
						border-top: 14px solid transparent;
						border-left: 14px solid transparent;
						content: '';
					}
					&:after {
						position: absolute;
						right: 3px;
						bottom: 4px;
						width: 12px;
						height: 12px;
						background: url(#{$img}download/ico-zoom.svg) no-repeat;
						background-size: 100% 100%;
						pointer-events: none;
						content: '';
					}
				}
				img {
					display: block;
					width: 100%;
				}
			}
			&__main {
				position: relative;
				display: flex;
				align-items: center;
				width: calc( 100% - 120px );
			}
			&__labels {
				position: absolute;
				right: 0;
				top: 0;
				font-size: 12px;
				line-height: 1;
				
				> span {
					display: inline-block;
					padding: 3px;
					margin-left: 3px;
					color: #fff;
					
					&.is-color-cat1 {
						background-color: #d4712f;
					}
					&.is-color-mat1 {
						background-color: #9a9b9c;
					}
					&.is-color-mat2 {
						background-color: #856d2e;
					}
					&.is-color-size {
						background-color: #3c89b2;
					}
					&.is-color-fprf {
						background-color: #1c5c26;
					}
				}
			}
			&__info {
				padding-top: 20px;
				
				> p {
					font-size: 12px;
					line-height: 1.4;
				}
			}
			&__ttl {
				margin-bottom: 10px;
				font-weight: $fw-normal;
				line-height: 1.5;
			}
			&__check {
				position: absolute;
				left: 0;
				top: 0;
				padding: 5px;
				
				> input {
					display: block;
					margin: 0;
				}
			}
			&__checks {
				width: calc( 100% + 10px );
				margin: 5px -5px -5px -5px;
				
				> li {
					display: block;
					border-top: 1px solid #ccc;
					
					> label {
						display: flex;
						padding: 3px 10px;
						align-items: center;
						
						> input {
							display: block;
							margin: 0 6px 0 0;
						}
					}
				}
			}
		}
	}
	&-control {
		position: fixed;
		top: 50%;
		right: 0;
		z-index: $z-control;
		width: 180px;
		transform: translateY(-50%);
		pointer-events: none;
		
		@media #{$sp} {
			top: auto;
			bottom: 0;
			right: 0;
			width: 100%;
			transform: none;
		}
		&__in {
			width: 100%;
			padding: 10px;
			box-sizing: border-box;
			border-left: 1px solid #3c89b2;
			border-top: 1px solid #3c89b2;
			border-bottom: 1px solid #3c89b2;
			border-radius: 4px 0 0 4px;
			background-color: #fff;
			pointer-events: auto;
			
			@media #{$sp} {
				display: flex;
				flex-wrap: wrap;
			}
		}
		&__count {
			padding: 13px;
			border-radius: 3px;
			background-color: #1a4e8a;
			text-align: center;
			color: #fff;
			font-size: 16px;
			line-height: 1;
			
			@media #{$sp} {
				width: 100%;
				padding: spvw(5);
				font-size: spvw(14);
				margin-bottom: spvw( 10 );
			}
		}
		&__select {
			margin-top: 10px;
			
			@media #{$sp} {
				margin-top: 0;
			}
			section {
				> h2 {
					color: #1a4e8a;
					
					@media #{$sp} {
						font-size: spvw( 12 );
						line-height: 1.2;
					}
				}
				> ul {
					display: flex;
					flex-wrap: wrap;
					
					@media #{$sp} {
						line-height: 1.5;
					}
					> li {
						min-width: 50%;
						padding-right: 6px;
						box-sizing: border-box;
						white-space: nowrap;
						
						> span {
							margin-left: 4px;
						}
					}
				}
			}
		}
		&__submit {
			margin-top: 15px;
			
			@media #{$sp} {
				margin-top: auto;
				margin-left: auto;
				margin-right: spvw(5);
				margin-bottom: spvw(5);
			}
			> button {
			}
		}
	}
}