.m-item {
	&01 {
		&__thumb {
			a {
				display: block;
				
				&.js-zoom-modal-target {
					position: relative;
					
					&:before {
						position: absolute;
						right: 1px;
						bottom: 1px;
						width: 0;
						height: 0;
						border-right: 14px solid #f8f8f8;
						border-bottom: 14px solid #f8f8f8;
						border-top: 14px solid transparent;
						border-left: 14px solid transparent;
						content: '';
					}
					&:after {
						position: absolute;
						right: 3px;
						bottom: 4px;
						width: 12px;
						height: 12px;
						background: url(#{$img}download/ico-zoom.svg) no-repeat;
						background-size: 100% 100%;
						pointer-events: none;
						content: '';
					}
				}
			}
			img {
				display: block;
				width: 100%;
			}
			&.is-video {
				position: relative;
				width: 100%;
				height: 0;
				padding-top: 100%;
				background-color: #000000;
				
				> video {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		&__info {
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;
		}
		&__ttl {
			color: #1a4e8a;
			font-size: 1.4rem;
			font-weight: $fw-bold;
			line-height: 1.5;
		}
		&__txt {
			margin-top: 0.6rem;
			font-size: 1.4rem;
			line-height: 1.5;
			
			&.is-double {
				margin-right: -118.6%;
			}
		}
	}
	&02 {
		&__ttl {
			> span,
			> a {
				display: block;
				padding: 0.8em 1em;
				background-color: #dde5ed;
				font-size: 1.8rem;
				font-weight: $fw-bold;
				line-height: 1.2;
				
				@media #{$sp} {
					font-size: 1.6rem;
				}
			}
			> span {
				color: #1a4e8a;
			}
			> a {
				position: relative;
				padding-left: 2em;
				color: #1a4e8a;
				
				&:before {
					position: absolute;
					left: 1em;
					top: 50%;
					transform: translateY(-35%) rotate(45deg);
					display: block;
					width: 6px;
					height: 6px;
					border-top: 2px solid #1a4e8a;
					border-right: 2px solid #1a4e8a;
					content: '';
				}
			}
		}
		&__content {
			padding-top: 1.8rem;
			padding-bottom: 1.8rem;
			
			> p {
				line-height: 1.8;
			}
		}
	}
}