.ui {
	&-datepicker {
		&-trigger {
			width: 40px;
			height: 40px;
			margin-left: 8px;
			border: 1px solid #666;
			border-radius: 3px;
			background: #fff url(#{$img}common/ico-calendar.svg) no-repeat center center;
			background-size: 1.4em auto;
			cursor: pointer;
			text-indent: -9999px;
		}
	}
}