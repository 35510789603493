.m-thumb {
	&-grad {
		position: relative;
		backface-visibility: hidden;
		
		&:after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 120%;
			// background: linear-gradient( rgba(0, 93, 149, 0) 50%, rgba(0, 93, 149, 0.6) 80%, rgba(0, 93, 149, 0.9) 100%);
			// background: linear-gradient( rgba(148, 185, 203, 0) 50%, rgba(148, 185, 203, 1) 100%);
			background: linear-gradient( rgba(0, 137, 134, 0) 50%, rgba(0, 137, 182, 1) 100%);
			content: '';
			transition: top .4s $ease-out-cubic;
			
			a:hover & {
				top: -20%;
			}
		}
	}
}