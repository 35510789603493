.m-logo-group {
	max-width: 910px;
	margin-left: auto;
	margin-right: auto;
	/*
	display: flex;
	justify-content: space-between;
	
	@media #{$sp} {
		flex-direction: column;
	}
	
	&__logo {
		width: 48%;
		
		@media #{$sp} {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__content {
		width: 45%;
		text-align: left;
		
		@media #{$sp} {
			width: 100%;
			margin-top: 2rem;
		}
	}
	*/
	&__sect {
		position: relative;
		padding-left: 20px;
		margin-top: 20px;
		
		&:first-child {
			margin-top: 0;
		}
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 5px;
			height: 100%;
			background: linear-gradient(#0082ba, #00619d, #003b7c, #00145a);
			content: '';
		}
	}
	&__ttl {
		margin-bottom: 0.5em;
		color: #1a4e8a;
		font-size: 2.0rem;
		line-height: 1.2;
	}
	&__txt {
		font-size: 1.6rem;
		line-height: 1.5;
	}
	&__list {
		font-size: 1.6rem;
		line-height: 1.5;
		
		> li {
			position: relative;
			padding-left: 0.75em;
			
			&:before {
				position: absolute;
				left: 0;
				top: 10px;
				border-left: 4px solid #000;
				border-top: 2px solid transparent;
				border-bottom: 2px solid transparent;
				content: '';
			}
		}
	}
	&-image {
		text-align: center;
	}
}