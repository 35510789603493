@charset "utf-8";

/*===============================================
 main
===============================================*/
.l-main {
	padding-top: 150px;
	
	/*
	@media #{$tb} {
		padding-top: 210px;
	}
	*/
	@media #{$sp} {
		padding-top: spvw( 90 );
	}
}

.l-in {
	// max-width: #{$pc-center}px;
	/*
	padding-left: 40px;
	padding-right: 40px;
	*/
	padding-left: 95px;
	padding-right: 95px;
	margin-left: auto;
	margin-right: auto;
	
	@media #{$sp} {
		padding-left: spvw( 15 );
		padding-right: spvw( 15 );
	}
	
	&--wide {
		padding-left: 40px;
		padding-right: 40px;
		
		@media #{$sp} {
			padding-left: spvw( 15 );
			padding-right: spvw( 15 );
		}
	}
}