@charset "utf-8";

/*===============================================
 main
===============================================*/
html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

body {
	background-color: $color-gray1;
	color: $fc-default;
	font-family: $ff-default;
	font-weight: $fw-normal;
	font-size: 1.6rem;
	line-height: 2;
	// letter-spacing: 0.125em;
	-webkit-font-feature-settings: "palt" 1;
	font-feature-settings: "palt" 1;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	
	@media #{$sp} {
		font-size: 1.3rem;
	}
}
