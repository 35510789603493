@keyframes blink{
	0% {
		opacity: .5;
	}
	100% {
		opacity: 1;
	}
}

@mixin blink() {
	animation:blink .25s ease-in-out infinite alternate;
}

@mixin blink_slow() {
	animation:blink 1.5s ease-in-out infinite alternate;
}
