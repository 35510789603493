.m-list {
	&-dot01,
	&-decimal01 {
		font-size: 1.2rem;
		line-height: #{(26 / 12)};
		
		li {
			position: relative;
		}
		ul {
			margin-left: 1.5em;
		}
	}
	&-dot01 {
		li {
			padding-left: 1em;
			
			&:before {
				position: absolute;
				left: 0;
				top: 0.8em;
				transform: rotate(45deg);
				width: 4px;
				height: 4px;
				border-top: 2px solid #333;
				border-right: 2px solid #333;
				content: '';
			}
		}
	}
	&-decimal01 {
		counter-reset: my-counter;
		
		li {
			padding-left: 1.2em;
			
			&:before {
				position: absolute;
				left: 0;
				top: 0.7em;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 1em;
				height: 1em;
				border: 1px solid #333;
				border-radius: 1em;
				font-size: 80%;
				line-height: 1;
				content: counter(my-counter);
				counter-increment: my-counter;
			}
		}
		ol {
			counter-reset: my-counter;
		}
	}
}