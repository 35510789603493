@charset "utf-8";

/*===============================================
 responsive element
===============================================*/
.issp {
	display: none;
}
.pc-inline {
	display: inline !important;
}
.pc-inline-block {
	display: inline-block !important;
}
.pc-block {
	display: block !important;
}
.tb-inline,
.tb-inline-block,
.tb-block {
	display: none !important;
}
.sp-inline,
.sp-inline-block,
.sp-block {
	display: none !important;
}

@media #{$tb} {
	.tb-inline {
		display: inline !important;
	}
	.tb-inline-block {
		display: inline-block !important;
	}
	.tb-block {
		display: block !important;
	}
}
@media #{$sp} {
	.issp {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		display: block;
	}
	.pc-inline,
	.pc-inline-block,
	.pc-block {
		display: none !important;
	}
	.sp-inline {
		display: inline !important;
	}
	.sp-inline-block {
		display: inline-block !important;
	}
	.sp-block {
		display: block !important;
	}
}
