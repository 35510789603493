.m-image {
	&-full,
	&-center {
		margin-top: 54px;
		
		&:first-child {
			margin-top: 0;
		}
	}
	&-full {
		> img {
			display: block;
			width: 100%;
		}
	}
	&-center {
		text-align: center;
	}
}