.m-breadcrumb {
	width: 100%;
	padding-top: 2.8rem;
	padding-bottom: 2.8rem;
	
	@media #{$sp} {
		padding-top: 1rem;
		padding-bottom: 1rem;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	&__in {
		@media #{$sp} {
			display: inline-block;
			max-width: none;
		}
	}
	ul {
		display: flex;
		font-size: 1.3rem;
		white-space: nowrap;
		
		@media #{$sp} {
			font-size: 1.1rem;
		}
		> li {
			&:before {
				margin: 0 1em;
				font-size: 75%;
				content: '＞';
			}
			&:first-child {
				&:before {
					display: none;
				}
			}
			> a {
				color: #1a4e8a;
			}
		}
	}
}