.m-btn {
	&01 {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 230px;
		// min-height: 100%;
		padding: 1em;
		background-color: #ffffff;
		border: 2px solid #1a4e8a;
		color: #1a4e8a;
		font-size: 1.8rem;
		font-weight: $fw-bold;
		line-height: 1.2;
		transition: border-color .2s, background-color .2s, color .2s;
		
		@media #{$sp} {
			min-width: 18rem;
			font-size: 1.5rem;
		}
		&:hover {
			opacity: 1;
			border-color: #00bab3;
			background-color: #00bab3;
			color: #fff;
		}
		&--small {
			min-width: 120px;
			padding: 0.8em;
			font-size: 1.6rem;
			
			@media #{$sp} {
				min-width: 9rem;
				font-size: 1.2rem;
			}
		}
		&--arw {
			> span {
				position: relative;
				padding-right: 1em;
				
				&:after {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-40%) rotate(45deg);
					width: 7px;
					height: 7px;
					border-top: 2px solid #1a4e8a;
					border-right: 2px solid #1a4e8a;
					content: '';
				}
			}
			&:hover {
				> span {
					&:after {
						border-color: #fff;
					}
				}
			}
		}
		&--arw-reverse {
			> span {
				position: relative;
				padding-left: 1em;
				
				&:before {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-40%) rotate(-45deg);
					width: 7px;
					height: 7px;
					border-top: 2px solid #1a4e8a;
					border-left: 2px solid #1a4e8a;
					content: '';
				}
			}
			&:hover {
				> span {
					&:before {
						border-color: #fff;
					}
				}
			}
		}
		&--small {
			> span {
				&:after {
					transform: translateY(-40%) rotate(45deg);
					width: 5px;
					height: 5px;
				}
			}
		}
		&--full {
			width: 100%;
			min-width: 0;
			box-sizing: border-box;
		}
		&--noborder {
			background-color: #ffffff;
			border: 0;
		}
	}
	&02 {
		display: block;
		padding: 0.75em 1em;
		border: 0;
		background-color: #0082ba;
		text-align: center;
		color: #ffffff;
		font-size: 2.4rem;
		font-family: $ff-default;
		font-weight: $fw-normal;
		line-height: 1.5;
		transition: background-color .2s, color .2s;
		cursor: pointer;
		
		@media #{$sp} {
			font-size: 1.8rem;
			font-weight: $fw-bold;
		}
		&[disabled] {
			background-color: #aaa;
			pointer-events: none;
			cursor: default;
		}
		&:hover {
			opacity: 1;
			background-color: #00bab3;
			color: #fff;
		}
		
		&--small {
			font-size: 1.6rem;
			
			@media #{$sp} {
				font-size: 1.4rem;
			}
		}
		&.is-inline {
			display: inline-block;
			min-width: 230px;
		}
	}
	&03 {
		width: 100%;
		padding: 10px 6px;
		border: 1px solid #3c89b2;
		border-radius: 3px;
		background: #addce9;
		color: #1a4e8a;
		font-size: 1.4rem;
		font-weight: bold;
		white-space: nowrap;
		cursor: pointer;
		transition: background-color .2s, color .2s;
		
		@media #{$sp} {
			padding: spvw(8) spvw(6);
			/*
			font-size: spvw(14);
			*/
		}
		
		&--clear {
			background: #fff;
		}
		&:hover {
			opacity: 1;
			background-color: #00bab3;
			border-color: #00bab3;
			color: #fff;
		}
	}
	&04 {
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 210px;
		min-height: 100%;
		padding: 0.8em 1.5em;
		border-radius: 2.6rem;
		background: linear-gradient( -45deg, #0082ba 35%, #59aed2 50%, #0082ba 65% );
		color: #fff;
		font-size: 1.4rem;
		line-height: 1.2;
		transition: border-color .2s, background-color .2s, color .2s;
		
		@media #{$sp} {
			min-width: 18rem;
			font-size: 1.5rem;
		}
		
		&:after {
			position: absolute;
			right: 1.35em;
			top: 50%;
			width: 0.4em;
			height: 0.4em;
			border-right: 1px solid #fff;
			border-top: 1px solid #fff;
			transform: rotate(45deg) translateY(-50%);
			content: '';
		}
	}
}

button.m-btn01,
button.m-btn02 {
	cursor: pointer;
	box-sizing: content-box;
}
button.m-btn01--full {
	box-sizing: border-box;
}