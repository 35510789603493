.m-section {
	padding-top: 5.6rem;
	padding-bottom: 4.8rem;
	
	&--bg-gray {
		background-color: #eeeeee;
	}
	@media #{$sp} {
		padding-top: 2.8rem;
		padding-bottom: 2.8rem;
	}
}