#wpcf7cpcnf {
	table {
		width: 100%;
		border-top: 1px solid #989898;
		border-bottom: 1px solid #989898;
		font-size: 1.6rem;
		line-height: 1.5;
		
		@media #{$sp} {
			display: block;
		}
		
		tbody {
			@media #{$sp} {
				display: block;
			}
		}
		tr {
			@media #{$sp} {
				display: block;
			}
			> th,
			> td {
				border-top: 1px dotted #989898;
				padding: 12px 22px;
				text-align: left;
				font-weight: $fw-normal;
				
				@media #{$sp} {
					display: block;
					padding: spvw(8) spvw(12);
				}
			}
			> th {
				white-space: nowrap;
				vertical-align: middle;
			}
			> td {
				@media #{$sp} {
					border-top: 0;
				}
			}
		}
	}
	.wpcf7cp-btns {
		display: flex;
		justify-content: center;
		
		button,
		input[type="submit"] {
			min-width: 10em;
			display: block;
			padding: 0.75em 1em;
			border: 0;
			background-color: #0082ba;
			text-align: center;
			color: #ffffff;
			font-size: 1.6rem;
			font-family: $ff-default;
			font-weight: $fw-normal;
			line-height: 1.5;
			transition: background-color .2s, color .2s;
			cursor: pointer;
			
			@media #{$sp} {
				font-size: 1.4rem;
				font-weight: $fw-bold;
			}
			&[disabled] {
				background-color: #aaa;
				pointer-events: none;
				cursor: default;
			}
			&:hover {
				opacity: 1;
				background-color: #00bab3;
				color: #fff;
			}
		}
	}
}
div#wpcf7cpcnf {
	z-index: 1;
}