.m-kv {
	&01 {
		position: relative;
		overflow: hidden;
		
		@media #{$tb} {
			min-height: 400px;
		}
		@media #{$sp} {
			min-height: 240px;
		}
		&__image {
			@media #{$tb} {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
			> img {
				display: block;
				width: 100%;
				
				@media #{$tb} {
					position: absolute;
					left: 50%;
					top: 0;
					transform: translateX(-50%);
					width: auto;
					max-width: none;
					height: 100%;
				}
			}
		}
		&__content {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		&__in {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			// max-width: 980px;
			height: 100%;
			padding-left: 95px;
			padding-right: 95px;
			margin: 0 auto;
			
			@media #{$sp} {
				padding-left: spvw( 15 );
				padding-right: spvw( 15 );
			}
		}
		&__ttl {
			color: $color-white;
			text-shadow: 0 0 6px #000;
			
			@media #{$tb} {
				margin-left: 20px;
			}
			&-ja {
				display: block;
				font-size: 4.2rem;
				font-weight: $fw-bold;
				line-height: 1;
				
				@media #{$sp} {
					font-size: 2.2rem;
				}
			}
			&-en {
				display: block;
				margin-top: 1.8rem;
				font-size: 2.4rem;
				font-family: $ff-en;
				font-weight: $fw-normal;
				line-height: 1;
				letter-spacing: 0.05em;
				
				@media #{$sp} {
					font-size: 1.2rem;
				}
			}
		}
	}
	&02 {
		position: relative;
		overflow: hidden;
		
		@media #{$tb} {
			min-height: 240px;
		}
		@media #{$sp} {
			min-height: 142px;
		}
		&__image {
			@media #{$tb} {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
			> img {
				display: block;
				width: 100%;
				
				@media #{$tb} {
					position: absolute;
					left: 50%;
					top: 0;
					transform: translateX(-50%);
					width: auto;
					max-width: none;
					height: 100%;
				}
			}
		}
		&__content {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		&__in {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			// max-width: 980px;
			height: 100%;
			padding-left: 95px;
			padding-right: 95px;
			margin: 0 auto;
			
			@media #{$sp} {
				padding-left: spvw( 15 );
				padding-right: spvw( 15 );
			}
		}
		&__ttl {
			color: $color-white;
			text-shadow: 0 0 6px #000;
			
			@media #{$tb} {
				margin-left: 2.0rem;
			}
			&-ja {
				display: block;
				font-size: 2.8rem;
				font-weight: $fw-bold;
				line-height: 1;
				
				@media #{$sp} {
					font-size: 2.2rem;
				}
			}
			&-en {
				display: block;
				margin-top: 1.0rem;
				font-size: 1.6rem;
				font-family: $ff-en;
				font-weight: $fw-normal;
				line-height: 1;
				letter-spacing: 0.05em;
				
				@media #{$sp} {
					font-size: 1.2rem;
				}
			}
		}
		&__catch {
			margin-top: 32px;
			color: #ffffff;
			font-size: 22px;
			line-height: #{(34 / 22)};
			text-shadow: 0 0 6px #000;
			
			@media #{$tb} {
				margin-left: 2.0rem;
			}
			@media #{$sp} {
				margin-left: 2.0rem;
				margin-top: 1.6rem;
				font-size: 1.4rem;
			}
		}
	}
	&03 {
		position: relative;
		overflow: hidden;
		
		@media #{$tb} {
			min-height: 160px;
		}
		@media #{$sp} {
			min-height: 120px;
		}
		&__image {
			@media #{$tb} {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
			> img {
				display: block;
				width: 100%;
				
				@media #{$tb} {
					position: absolute;
					left: 50%;
					top: 0;
					transform: translateX(-50%);
					width: auto;
					max-width: none;
					height: 100%;
				}
			}
		}
		&__content {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		&__in {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			// max-width: 980px;
			height: 100%;
			padding-left: 95px;
			padding-right: 95px;
			margin: 0 auto;
			
			@media #{$sp} {
				padding-left: spvw( 15 );
				padding-right: spvw( 15 );
			}
		}
		&__ttl {
			color: $color-white;
			text-shadow: 0 0 6px #000;
			
			@media #{$tb} {
				margin-left: 20px;
			}
			&-ja {
				display: block;
				font-size: 2.8rem;
				font-weight: $fw-bold;
				line-height: 1;
				
				@media #{$sp} {
					font-size: 2.2rem;
				}
			}
			&-en {
				display: block;
				margin-top: 1.0rem;
				font-size: 1.6rem;
				font-family: $ff-en;
				font-weight: $fw-normal;
				line-height: 1;
				letter-spacing: 0.05em;
				
				@media #{$sp} {
					font-size: 1.2rem;
				}
			}
		}
	}
	&04 {
		position: relative;
		overflow: hidden;
		
		&__image {
			@media #{$sp} {
				margin-left: percentage( ( 980 - 1280 ) / 2 / 980 );
				margin-right: percentage( ( 980 - 1280 ) / 2 / 980 );
			}
			> img {
				display: block;
				width: 100%;
			}
		}
		&__content {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		&__in {
			position: relative;
			max-width: 980px;
			height: 100%;
			margin: 0 auto;
		}
		&__ttl {
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			white-space: nowrap;
			font-size: 4.2rem;
			line-height: #{(60 / 42)};
			text-shadow: 0 0 6px #fff;
			
			@media #{$sp} {
				font-size: spvw(20);
			}
		}
	}
	&05 {
		position: relative;
		overflow: hidden;
		
		&__image {
			max-width: 1280px;
			margin: 0 auto;
			
			@media #{$sp} {
				margin-left: percentage( ( 980 - 1280 ) / 980 );
			}
			> img {
				display: block;
				width: 100%;
			}
			&--left {
				margin-left: 0;
				margin-right: auto;
			}
			&--right {
				margin-left: auto;
				margin-right: 0;
			}
		}
		&__content {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		&__in {
			position: relative;
			margin-left: 95px;
			margin-right: 95px;
			min-height: 100%;
			
			@media #{$sp} {
				margin-left: spvw( 15 );
				margin-right: spvw( 15 );
			}
		}
		&-ttl {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			white-space: nowrap;
			
			&__catch {
				margin-bottom: 0.5em;
				font-size: 3.0rem;
				font-family: $ff-catch;
				line-height: #{(52 / 40)};
				text-shadow: 0 0 4px #fff;
				
				@media #{$tb} {
					margin-bottom: 0.25em;
					font-size: 2.4rem;
				}
				@media #{$sp} {
					font-size: 1.8rem;
				}
			}
			&__name {
				display: inline-block;
				color: #1a4e8a;
				font-size: 2.2rem;
				line-height: 1.3;
				
				@media #{$tb} {
					font-size: 1.6rem;
				}
				@media #{$sp} {
					font-size: 1.1rem;
				}
			}
		}
		&-ttl--center {
			left: 0;
			text-align: center;
		}
		&-ttl--left {
			left: 0;
			right: auto;
		}
	}
}