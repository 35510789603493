.m-prevnext {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
	
	&__prev,
	&__next {
		width: 48%;
	}
}