.m-box {
	border: 6px solid #269bc1;
	background-color: #fff;
	
	@media #{$sp} {
		border-width: 4px;
	}
	&__in {
		padding: 4.2rem;
		
		@media #{$sp} {
			padding: 2.1rem;
		}
	}
	.m-column {
		margin-top: 0;
		
		&__image {
			width: 348px;
			
			@media #{$sp} {
				width: 100%;
			}
			&-caption {
				margin-top: 1em;
				line-height: 1.65;
			}
		}
		&__content {
			width: calc( 100% - 406px );
			
			@media #{$sp} {
				width: 100%;
				margin-top: 2.1rem;
			}
		}
		&__txt {
			line-height: 2.2;
		}
	}
}