.m-links {
	&-col04,
	&-col03,
	&-col02,
	&-col01 {
		max-width: #{$pc-center}px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
		
		&:first-child {
			margin-top: 0;
		}
	}
	&-col04 {
		> ul {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			
			> li {
				width: percentage( 230 / $pc-center );
				margin-left: percentage( ($pc-center - 230 * 4) / 3 / $pc-center );
				
				&:nth-child(4n+1) {
					margin-left: 0;
				}
				
				@media #{$sp} {
					width: 49%;
					margin-left: 2%;
					margin-top: 2%;
					
					&:nth-child(4n+1) {
						margin-left: 2%;
					}
					&:nth-child(-n+2) {
						margin-top: 0;
					}
					&:nth-child(2n+1) {
						margin-left: 0;
					}
				}
				> a {
					/*
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 60px;
					border: 1px solid #1a4e8a;
					box-sizing: border-box;
					background-color: #ffffff;
					color: #1a4e8a;
					font-size: 1.8rem;
					line-height: 1.2;
					
					@media #{$sp} {
						font-size: 1.6rem;
					}
					*/
				}
			}
		}
	}
	&-col03 {
	}
	&-col02 {
	}
	&-col01 {
		> a {
			/*
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 80px;
			background-color: #1a4e8a;
			color: #ffffff;
			font-size: 2.4rem;
			font-weight: $fw-medium;
			line-height: 1;
			
			@media #{$sp} {
				height: 60px;
				font-size: 2.0rem;
			}
			*/
		}
	}
	&-detail {
		display: flex;
		flex-wrap: wrap;
		margin-top: 16px;
		font-size: 14px;
		line-height: 1;
		
		&:first-child {
			margin-top: 0;
		}
		> li {
			margin-right: 9px;
			margin-bottom: 6px;
			
			&:last-child {
				margin-right: 0;
				margin-bottom: 0;
			}
			> a,
			> span {
				display: block;
				padding: 4px 16px 5px;
				border-radius: 3px;
				background-color: #1a4e8a;
				color: #ffffff;
				white-space: nowrap;
				
				&.is-map {
					background-color: #008bdb;
					
					&:before {
						display: inline-block;
						width: 11px;
						height: 15px;
						margin-right: 6px;
						vertical-align: -2px;
						background: url(#{$img}company/network/ico-map.png) no-repeat;
						content: '';
					}
				}
				&.is-homepage {
					padding-left: 10px;
					background-color: #D986BA;
					
					&:before {
						display: inline-block;
						width: 14px;
						height: 14px;
						margin-right: 6px;
						vertical-align: -1px;
						background: url(#{$img}company/network/ico-homepage.png) no-repeat;
						content: '';
					}
				}
				&.is-blank {
					padding-right: 6px;
					
					&:after {
						display: inline-block;
						width: 11px;
						height: 9px;
						margin-left: 6px;
						vertical-align: 1px;
						background: url(#{$img}company/network/ico-blank.png) no-repeat;
						content: '';
					}
				}
			}
		}
	}
}