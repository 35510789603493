.p-common {
	background-color: #fff;
	
	&__main {
		padding-top: 5.2rem;
		padding-bottom: 4.8rem;
		
		@media #{$sp} {
			padding-top: 2.6rem;
			padding-bottom: 2.4rem;
		}
	}
}