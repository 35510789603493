@charset "utf-8";

//-----------------------------------------------
// hover
//-----------------------------------------------
@mixin hover( $opacity:.7 ) {
	transition: opacity .2s linear;
	opacity: 1;
	
	&:hover {
		opacity: $opacity;
	}
}

//-----------------------------------------------
// clearfix
//-----------------------------------------------
@mixin clearfix() {
	*zoom: 1;
	
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

//-----------------------------------------------
// vw
//-----------------------------------------------
@function vw( $size, $base ) {
	@return 100vw / $base * $size;
}

@function vh( $size, $base ) {
	@return 100vh / $base * $size;
}

//-----------------------------------------------
// spvw
//-----------------------------------------------
@function spvw( $size ) {
	@return vw( $size, $sp-base );
}

@function spvh( $size ) {
	@return vh( $size, $sp-base-h );
}

//-----------------------------------------------
// pcvw
//-----------------------------------------------
@function pcvw( $size ) {
	@return vw( $size, $pc-base );
}

@function pcvh( $size ) {
	@return vh( $size, $pc-base-h );
}