.m-map {
	&01 {
		position: relative;
		width: 100%;
		padding-top: percentage( 230 / 340 );
		
		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
}