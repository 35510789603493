.gsc-search-button-v2 {
	position: relative;
	width: 41px !important;
	height: 41px !important;
	padding: 0 !important;
	border: 0 !important;
	border-radius: 3px !important;
	background: #1a4e8a !important;
	transition: background-color .2s;
	cursor: pointer !important;
	
	&:hover,
	&:active {
		background-color: #00bab3 !important;
	}
	
	svg {
		width: 18px !important;
		height: 18px !important;
		pointer-events: none !important;
	}
}