.m-zoom-modal {
	position: fixed;
	left: 0;
	top: 0;
	z-index: $z-zoom;
	width: 100%;
	height: 100%;
	opacity: 0;
	pointer-events: none;
	transition: opacity 1s;
	
	&.is-visible {
		opacity: 1;
		pointer-events: auto;
	}
	&__bg,
	&__in {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	&__bg {
		background-color: #000;
		opacity: 0.65;
	}
	&__in {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		img {
			max-width: 95%;
			max-height: 95%;
			width: auto;
			height: auto;
			opacity: 0;
			transition: opacity 1s;
			
			&.is-complete {
				opacity: 1;
			}
		}
	}
	&__caption {
		margin-top: 0.5em;
		text-align: center;
		color: #fff;
		font-size: 14px;
	}
	&__close {
		position: absolute;
		right: 15px;
		top: 15px;
		width: 30px;
		height: 30px;
		cursor: pointer;
		
		&:before,
		&:after {
			position: absolute;
			left: 0;
			top: calc(50% - 2px);
			width: 100%;
			height: 4px;
			background-color: #fff;
			content: '';
		}
		&:before {
			transform: rotate(-45deg);
		}
		&:after {
			transform: rotate(45deg);
		}
	}
}