.m-index01 {
	display: flex;
	flex-wrap: wrap;
	max-width: #{$pc-center}px;
	margin-left: auto;
	margin-right: auto;
	
	&--centering {
		justify-content: center;
	}
	&-item {
		width: percentage( 300 / $pc-center );
		margin-left: percentage( ($pc-center - 300 * 3) / 2 / $pc-center );
		margin-top: 2.8rem;
		
		&:nth-child(-n+3) {
			margin-top: 0;
		}
		&:nth-child(3n+1) {
			margin-left: 0;
		}
		@media #{$sp} {
			width: 48%;
			margin-left: 4%;
			margin-top: 2%;
			
			&:nth-child(-n+3) {
				margin-top: 2%;
			}
			&:nth-child(3n+1) {
				margin-left: 4%;
			}
			&:nth-child(-n+2) {
				margin-top: 0;
			}
			&:nth-child(2n+1) {
				margin-left: 0;
			}
		}
		&__link {
			display: block;
			color: #1a4e8a;
		}
		&__thumb {
			overflow: hidden;
			
			> img {
				display: block;
				width: 100%;
				transition: opacity .4s, transform .4s ease-out;
			}
		}
		&__link:hover &__thumb {
			img {
				opacity: 0.75;
				transform: scale(1.1);
			}
		}
		&__ttl {
			padding-top: 1.4rem;
			padding-bottom: 1.4rem;
			text-align: center;
			font-weight: $fw-bold;
			line-height: 1.2;
		}
	}
}