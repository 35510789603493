.m-imagebox {
	position: relative;
	display: inline-block;
	
	&.is-full {
		display: block;
	}
	&:before {
		position: absolute;
		left: 0;
		top: 16px;
		width: calc(100% - 24px);
		height: calc( 100% - 24px);
		border: 1px solid #a1a1a1;
		content: '';
	}
	&__in {
		position: relative;
		padding-left: 24px;
		padding-bottom: 24px;
		
		> img {
			display: block;
		}
	}
}