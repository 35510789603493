.m-interview {
	&-profile {
		margin-top: 100px;
		padding: 44px;
		border: 6px solid #269bc1;
		
		@media #{$sp} {
			margin-top: 5.0rem;
			padding: 2.2rem;
			border-width: 0.3rem;
		}
		
		&__in {
			display: flex;
			justify-content: space-between;
			
			@media #{$sp} {
				flex-direction: column;
			}
		}
		&__info {
			width: 220px;
			
			@media #{$sp} {
				width: 100%;
			}
			&-in {
				margin-top: 1rem;
				
				@media #{$sp} {
					display: flex;
					align-items: center;
				}
			}
		}
		&__pic {
			@media #{$sp} {
				width: 12rem;
			}
			
			> img {
				display: block;
				width: 100%;
			}
		}
		&__main {
			margin-top: 2.4rem;
			
			@media #{$sp} {
				width: calc( 100% - 14rem );
				margin-top: 0;
				margin-left: auto;
			}
			
			> p {
				margin-top: 1rem;
				line-height: 1.5;
				
				&:first-child {
					margin-top: 0;
				}
			}
		}
		&__name {
			margin-top: 2.0rem;
			font-size: 1.6rem;
			font-weight: $fw-normal;
			line-height: 1;
		}
		&__ttl {
			font-size: 1.8rem;
			font-family: $ff-en;
			line-height: 1;
		}
		&__schedule {
			width: 240px;
			padding: 28px;
			box-sizing: border-box;
			background-color: #e2e2e2;
			
			@media #{$sp} {
				width: 100%;
				padding: 1.4rem;
				margin-top: 1.4rem;
			}
			&-ttl {
				margin-bottom: 1em;
				font-size: 1.8rem;
				font-family: $ff-en;
				line-height: 1;
			}
			> p {
				font-size: 1.4rem;
				line-height: 1;
			}
			> dl {
				display: flex;
				flex-wrap: wrap;
				margin-top: 2.8rem;
				line-height: 1.5;
				
				> dt {
					width: 3.5em;
					margin-top: 1.6rem;
					font-family: $ff-en;
				}
				> dd {
					width: calc( 100% - 4em);
					margin-top: 1.6rem;
				}
				
			}
		}
		&__txt {
			width: calc( 100% - 540px );
			
			@media #{$sp} {
				width: 100%;
				margin-top: 2.0rem;
			}
		}
	}
	&-nav {
		margin-top: 8.0rem;
		
		@media #{$sp} {
			margin-top: 4.0rem;
		}
		
		&__list {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		&-item {
			width: 24%;
			margin-left: 1.333%;
			margin-top: 1.333%;
			
			&:nth-child(4n+1) {
				margin-left: 0;
			}
			&:nth-child(-n+4) {
				margin-top: 0;
			}
			@media #{$sp} {
				width: 49%;
				margin-left: 2%;
				margin-top: 2%;
				
				&:nth-child(4n+1) {
					margin-left: 2%;
				}
				&:nth-child(-n+4) {
					margin-top: 2%;
				}
				&:nth-child(2n+1) {
					margin-left: 0;
				}
				&:nth-child(-n+2) {
					margin-top: 0;
				}
			}
			&__link {
				position: relative;
				display: block;
				padding: 16px 8px 8px 8px;
				/*
				color: #1a4e8a;
				*/
				color: #000;
				box-shadow: 0 0 10px rgba(0,0,0,0.2);
				
				&:before {
					position: absolute;
					right: 8px;
					top: 8px;
					z-index: 1;
					width: 22px;
					height: 22px;
					background-color: #0189b6;
					content: '';
				}
				&:after {
					position: absolute;
					right: 17px;
					top: 15px;
					z-index: 2;
					width: 7px;
					height: 7px;
					border-right: 1px solid #fff;
					border-top: 1px solid #fff;
					transform: rotate(45deg);
					content: '';
				}
			}
			&__name {
				padding: 12px;
				font-size: 1.6rem;
				font-weight: $fw-normal;
				line-height: 1.5;
				
				@media #{$sp} {
					font-size: 1.2rem;
				}
				
				/*
				strong {
					font-size: 2.0rem;
					font-weight: $fw-bold;
					
					@media #{$sp} {
						font-size: 1.6rem;
					}
				}
				*/
				strong {
					font-size: 100%;
					font-weight: $fw-bold;
				}
				font {
					color: #1a4e8a;
				}
			}
			&__thumb {
				overflow: hidden;
				
				> img {
					display: block;
					width: 100%;
					transition: opacity .4s, transform .4s ease-out;
				}
			}
			&__link:hover &__thumb {
				> img {
					opacity: 0.75;
					transform: scale(1.1);
				}
			}
		}
	}
}