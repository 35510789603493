@charset "utf-8";

$color-black: #000000;
$color-white: #ffffff;
$color-gray1: #f7f7f7;
$color-gray2: #5a5a5a;
$color-gray3: #cecece;
$color-gray4: #dedede;
$color-gray5: #3f3f3f;
$color-gray6: #999999;
$color-red: #990001;