.m-figcaption {
	&01 {
		margin-top: -30%;
		width: 31%;
		
		@media #{$sp} {
			margin-top: 3%;
			width: 100%;
		}
		
		img {
			max-width: 100%;
			height: auto;
		}
	}
}