.m-slider {
	&01 {
		position: relative;
		
		@media #{$tb} {
			margin-left: 28px;
			margin-right: 28px;
		}
		&__in {
			width: 100%;
			overflow: hidden;
		}
		&__container {
			position: relative;
			display: flex;
			
			> * {
				width: percentage( 215 / $pc-center );
				margin-right: percentage( 40 / $pc-center );
				flex: 0 0 auto;
				
				@media #{$sp} {
					width: 48%;
					margin-right: 4%;
				}
				
				&:last-child {
					margin-right: 0;
				}
			}
		}
		&__prev,
		&__next {
			position: absolute;
			top: 50%;
			width: 11px;
			height: 27px;
			transform: translateY(-50%);
			cursor: pointer;
			
			&.is-disabled {
				opacity: 0.1;
				cursor: default;
				pointer-events: none;
			}
		}
		&__prev {
			left: -28px;
			background: url(#{$img}company/network/arw-prev01.png) no-repeat;
		}
		&__next {
			right: -28px;
			background: url(#{$img}company/network/arw-next01.png) no-repeat;
		}
	}
	&02 {
		position: relative;
		margin-top: 24px;
		border-radius: 15px;
		background-color: #eeeeee;
		padding: 28px 70px 14px;
		
		@media #{$sp} {
			border-radius: 8px;
			padding: 14px 35px 7px;
		}
		
		&__in {
			width: 100%;
			overflow: hidden;
		}
		&__container {
			position: relative;
			display: flex;
			font-size: 1.1rem;
			line-height: 1.2;
			
			> li {
				width: percentage( 120 / 840 );
				margin-right: percentage( 24 / 840 );
				flex: 0 0 auto;
				
				@media #{$sp} {
					width: 32%;
					margin-right: 2%;
				}
				
				&:last-child {
					margin-right: 0;
				}
				> a {
					display: block;
					
					> figure {
						overflow: hidden;
						
						> img {
							display: block;
							width: 100%;
							transition: opacity .4s, transform .4s ease-out;
						}
					}
					&:hover > figure {
						> img {
							opacity: 0.75;
							transform: scale(1.05);
						}
					}
					> span {
						display: block;
						padding-top: 1.1rem;
						padding-bottom: 1.1rem;
						color: #1a4e8a;
					}
				}
			}
		}
		&__prev,
		&__next {
			position: absolute;
			top: 50%;
			width: 30px;
			height: 29px;
			transform: translateY(-50%);
			cursor: pointer;
			
			@media #{$sp} {
				width: 20px;
				height: 20px;
			}
			&.is-disabled {
				opacity: 0.1;
				cursor: default;
				pointer-events: none;
			}
		}
		&__prev {
			left: 20px;
			background: url(#{$img}company/network/arw-prev02.png) no-repeat;
			
			@media #{$sp} {
				left: 6px;
				background-size: contain;
			}
		}
		&__next {
			right: 20px;
			background: url(#{$img}company/network/arw-next02.png) no-repeat;
			
			@media #{$sp} {
				right: 6px;
				background-size: contain;
			}
		}
	}
}