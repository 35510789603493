.m-column {
	&01,
	&02 {
		display: flex;
		justify-content: space-between;
		
		&--image-left {
			flex-direction: row-reverse;
			
			@media #{$sp} {
				flex-direction: column;
			}
		}
		&--image-left-sp-reverse {
			@media #{$sp} {
				flex-direction: column-reverse;
			}
		}
		&--image-right {
			flex-direction: row;
			
			@media #{$sp} {
				flex-direction: column;
			}
		}
		&--image-right-sp-reverse {
			@media #{$sp} {
				flex-direction: column-reverse;
			}
		}
		&--center {
			align-items: center;
		}
		&:first-child {
			margin-top: 0;
		}
		&__image {
			text-align: center;
			
			@media #{$sp} {
				margin-top: 2.0rem;
			}
		}
		&--image-left-sp-reverse &__image,
		&--image-right-sp-reverse &__image {
			@media #{$sp} {
				margin-top: 0;
			}
		}
		&__content {
			@media #{$sp} {
				padding-right: 20px;
				padding-left: 20px;
				max-width: 100%;
				box-sizing: border-box;
			}
		}
		&--image-left-sp-reverse &__content,
		&--image-right-sp-reverse &__content {
			@media #{$sp} {
				margin-top: 2.0rem;
			}
		}
	}
	&01 {
		&__image {
			width: 400px;
			
			@media #{$sp} {
				width: 100%;
			}
		}
		&__content {
			width: calc( 100% - 440px);
			
			@media #{$sp} {
				width: 100%;
			}
			
			figure {
				max-width: 400px;
				
				@media #{$sp} {
					max-width: 100%;
				}
			}
		}
	}
	&02 {
		&__image {
			width: calc( 50% - 10px );
			text-align: center;
			
			@media #{$sp} {
				width: 100%;
			}
		}
		&__content {
			width: calc( 50% - 10px );
			
			@media #{$sp} {
				width: 100%;
			}
		}
	}
}