@charset "utf-8";

/*===============================================
 wrap
===============================================*/
.l-wrap {
	&.with-download-control {
		@media #{$sp} {
			padding-bottom: spvw( 120 );
		}
	}
}