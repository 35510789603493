.m-ttl {
	&01 {
		margin-bottom: 4.0rem;
		text-align: center;
		font-size: 2.8rem;
		font-weight: $fw-bold;
		line-height: 1.5;
		
		@media #{$sp} {
			margin-bottom: 18px;
			font-size: 2.2rem;
		}
		&--left {
			text-align: left;
		}
		&--right {
			text-align: right;
		}
		
		> span {
			display: inline-block;
			padding-bottom: 12px;
			border-bottom: 4px solid #1a4e8a;
			
			@media #{$sp} {
				padding-bottom: 8px;
				border-bottom-width: 2px;
			}
		}
	}
	&01en {
		margin-bottom: 4.0rem;
		text-align: center;
		font-size: 3.2rem;
		font-weight: $fw-bold;
		line-height: 1.25;
		
		@media #{$sp} {
			margin-bottom: 18px;
			font-size: 2.2rem;
		}
		.en {
			display: inline-block;
			padding-left: 2em;
			padding-right: 2em;
			padding-bottom: 0.35em;
			border-bottom: 4px solid #0082ba;
			
			@media #{$sp} {
				border-bottom-width: 2px;
			}
		}
		.ja {
			display: block;
			margin-top: 0.75em;
			font-size: 1.8rem;
		}
	}
	&02 {
		margin-bottom: 2.0rem;
		font-size: 3.0rem;
		line-height: #{(44 / 30)};
		
		@media #{$sp} {
			font-size: 2.2rem;
		}
		
		&--s {
			margin-bottom: 6.0rem;
			font-size: 2.4rem;
			line-height: 1.2;
			
			@media #{$sp} {
				font-size: 2.2rem;
			}
		}
	}
	&03 {
		margin-bottom: 3.6rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid #0082ba;
		color: #231815;
		font-size: 2.4rem;
		font-weight: $fw-bold;
		line-height: 1.5;
		
		@media #{$sp} {
			font-size: 1.8rem;
		}
	}
	&04 {
		margin-bottom: #{(29 / 24)}em;
		color: #000000;
		font-size: 2.4rem;
		font-weight: $fw-bold;
		line-height: 1.5;
		
		@media #{$sp} {
			font-size: 1.8rem;
		}
		
		small {
			font-size: 72%;
		}
	}
	&05 {
		margin-bottom: 1em;
		color: #333333;
		font-size: 3.0rem;
		font-weight: $fw-bold;
		line-height: #{(44 / 30)};
		
		@media #{$sp} {
			font-size: 2.2rem;
		}
	}
	&06 {
		margin-bottom: 2em;
		color: #001957;
		font-size: 2.0rem;
		font-weight: $fw-normal;
		line-height: 1.5;
		
		@media #{$sp} {
			font-size: 1.8rem;
		}
	}
	&07 {
		position: relative;
		margin-bottom: #{(32 / 18)}em;
		padding-left: 1.4rem;
		color: #000000;
		font-size: 1.8rem;
		font-weight: $fw-normal;
		line-height: 1.5;
		
		@media #{$sp} {
			font-size: 1.6rem;
		}
		
		&:before {
			position: absolute;
			left: 0;
			top: .3rem;
			bottom: .3rem;
			width: .5rem;
			background-color: #1a4e8a;
			content: '';
		}
	}
	&08 {
		margin-bottom: 1.5em;
		color: #1a4e8a;
		font-size: 1.8rem;
		font-weight: $fw-bold;
		line-height: 1.5;
		
		@media #{$sp} {
			font-size: 1.6rem;
		}
	}
	&-box01 {
		margin-bottom: 1em;
		font-size: 2.2rem;
		font-family: $ff-en;
		font-weight: $fw-bold;
		line-height: 1.2;
		
		@media #{$sp} {
			font-size: 1.6rem;
		}
	}
	&-box02 {
		margin-bottom: 0.8em;
		font-size: 3.0rem;
		font-weight: $fw-bold;
		line-height: 1.2;
		
		@media #{$sp} {
			font-size: 2.2rem;
		}
	}
}