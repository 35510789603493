@charset "utf-8";

/*===============================================
 common
===============================================*/
img {
	max-width: 100%;
	height: auto;
}

a {
	color: inherit;
	text-decoration: none;
	transition: opacity .1s linear;
	backface-visibility: hidden;
	cursor: default;

	&[href] {
		cursor: pointer;
		
		&:hover {
			opacity: 0.7;
		}
	}
	&[href^="tel:"] {
		cursor: default;
		pointer-events: none;
		
		@media #{$sp} {
			cursor: pointer;
			pointer-events: auto;
		}
	}
}

.alt {
	display: none;
}

sup {
	vertical-align: super;
	font-size: smaller;
}

.inline-block {
	display: inline-block;
	vertical-align: top;
	text-align: left;
	
	> img {
		max-width: 100%;
		height: auto;
	}
}