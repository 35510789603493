.m-tag {
	&01,
	&02 {
		display: inline-block;
		padding: 0.25em 1em 0.3em;
		margin-left: 0.25em;
		margin-right: 0.25em;
		border-radius: 2px;
		line-height: 1;
	}
	&01 {
		background-color: #008bdb;
		color: #ffffff;
	}
	&02 {
		background-color: #1a4e8a;
		color: #ffffff;
	}
}