.m-grid {
	&-col02,
	&-col03,
	&-col04 {
		display: flex;
		flex-wrap: wrap;
		
		&--centering {
			justify-content: center;
		}
	}
	&-item {
		&__thumb {
			border: 1px solid #cccccc;
			
			img {
				display: block;
				width: 100%;
			}
		}
		&__content {
			padding-top: 1.6rem;
			padding-bottom: 1.6rem;
		}
		&__ttl {
			color: #1a4e8a;
			line-height: 1.5;
		}
		&__txt {
			margin-top: 0.4rem;
			line-height: 1.5;
			
			@media #{$sp} {
				font-size: 1.2rem;
			}
		}
	}
	&-col02 &-item {
		width: percentage( 470 / $pc-center );
		margin-left: percentage( ($pc-center - 470 * 2) / $pc-center );
		margin-top: 2.8rem;
		
		&:nth-child(-n+2) {
			margin-top: 0;
		}
		&:nth-child(2n+1) {
			margin-left: 0;
		}
		@media #{$sp} {
			width: 100%;
			margin-left: 0;
			
			&:nth-child(-n+2) {
				margin-top: 2.8rem;
			}
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&-col03 &-item {
		width: percentage( 300 / $pc-center );
		margin-left: percentage( ($pc-center - 300 * 3) / 2 / $pc-center );
		margin-top: 4.4rem;
		
		&:nth-child(-n+3) {
			margin-top: 0;
		}
		&:nth-child(3n+1) {
			margin-left: 0;
		}
		@media #{$sp} {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin-left: 0;
			margin-top: 2.0rem;
			
			&:nth-child(-n+3) {
				margin-top: 2.0rem;
			}
			&:nth-child(3n+1) {
				margin-left: 0;
			}
			&:first-child {
				margin-top: 0;
			}
			&__thumb {
				width: 48%;
			}
			&__content {
				width: 48%;
			}
		}
	}
	&-col04 &-item {
		width: percentage( 215 / $pc-center );
		margin-left: percentage( ($pc-center - 215 * 4) / 3 / $pc-center );
		margin-top: 3.2rem;
		
		&:nth-child(-n+4) {
			margin-top: 0;
		}
		&:nth-child(4n+1) {
			margin-left: 0;
		}
		@media #{$sp} {
			width: 48%;
			margin-left: 4%;
			margin-top: 1.6rem;
			
			&:nth-child(-n+4) {
				margin-top: 1.6rem;
			}
			&:nth-child(4n+1) {
				margin-left: 4%;
			}
			&:nth-child(-n+2) {
				margin-top: 0;
			}
			&:nth-child(2n+1) {
				margin-left: 0;
			}
		}
	}
}