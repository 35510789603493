.m-logos {
	&01 {
		display: flex;
		flex-wrap: wrap;
		margin-right: -20px;
		
		> li {
			max-width: 200px;
			margin: 0 20px 20px 0;
			
			@media #{$sp} {
				max-width: spvw(250);
			}
			a,
			img {
				display: block;
			}
		}
	}
}